<template>
  <div
    class="flex items-center justify-center loader"
    :class="{ 'loader--vertical': contentPosition === 'vertical' }"
    :style="{
      paddingTop: `${paddingTop}px`,
      paddingBottom: `${paddingBottom}px`,
    }"
  >
    <i class="circle-loader"></i> <span v-html="text"></span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    text: {
      type: String,
      default() {
        return "";
      },
    },
    contentPosition: {
      type: String,
      default() {
        return "";
      },
    },
    paddingTop: {
      type: Number,
      default() {
        return 0;
      },
    },
    paddingBottom: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.loader {
  &--vertical {
    flex-direction: column;
    text-align: center;
    .circle-loader {
      margin: 0 0 7px 0;
    }
  }
  &.space-0 {
    .circle-loader {
      margin: 0;
    }
  }
}
</style>
