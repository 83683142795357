<template>
  <div class="home flex">
    <aside class="home__left-side">
      <div class="home__left-side-cta">
        <a-button
          type="primary"
          class="text-left w-full"
          @click="naviToProducts"
        >
          <font-awesome-icon icon="file-alt" />
          Create New Study
        </a-button>
      </div>

      <ul class="home__left-side-nav">
        <li
          v-if="this.projects.length !== 1"
          @click="setSelectedProject(0)"
          :class="{ 'is-selected': selectProject === 0 }"
          class="flex items-center home__left-side-nav-item"
        >
          <font-awesome-icon
            :icon="selectProject === 0 ? 'folder-open' : 'folder'"
          />
          All Studies
        </li>

        <li
          v-for="project in sortedProjects"
          :key="project.ProjectId"
          class="flex items-center home__left-side-nav-item"
          @click="setSelectedProject(project.ProjectId)"
          :class="{ 'is-selected': selectProject === project.ProjectId }"
        >
          <font-awesome-icon
            :icon="
              selectProject === project.ProjectId ? 'folder-open' : 'folder'
            "
          />

          <span>{{ project.ProjectName }}</span>

          <div class="flex justify-end flex-1 home__left-side-nav-tools">
            <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
              <template slot="title">
                <span>Edit a folder name</span>
              </template>
              <button
                type="button"
                class="btn-reset"
                @click="
                  (modals.projectServicesModal = true),
                    (activeProjectServices = 'UpdateProjects'),
                    (activeProjectServicesID = project.ProjectId),
                    (newProjName = project.ProjectName)
                "
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
            </a-tooltip>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
              <template slot="title">
                <span v-if="!project.Pinned">Pin a folder</span>
                <span v-else>Unpin a folder</span>
              </template>
              <button
                type="button"
                class="btn-reset"
                @click="
                  (activeProjectServicesID = project.ProjectId),
                    (activeProjectServices = 'PinProjects'),
                    projectServices(project.Pinned)
                "
              >
                <font-awesome-icon
                  icon="star"
                  :class="{ pinned: project.Pinned }"
                />
              </button>
            </a-tooltip>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
              <template slot="title">
                <span>Delete a folder</span>
              </template>
              <button
                type="button"
                class="btn-reset"
                @click="deleteProject(project.ProjectId)"
              >
                <font-awesome-icon icon="trash-alt" />
              </button>
            </a-tooltip>
          </div>
        </li>

        <li
          @click="
            (modals.projectServicesModal = true),
              (activeProjectServices = 'SaveProject'),
              (newProjName = '')
          "
          class="
            flex
            items-center
            home__left-side-nav-item home__left-side-nav-item--add
          "
        >
          <font-awesome-icon icon="folder-plus" />
          Add Folder
        </li>
      </ul>
    </aside>

    <div class="home__right-side">
      <div class="home__right-side-filters">
        <div class="page-title">
          <icon-base
            :width="24"
            :height="24"
            :viewBox1="24"
            :viewBox2="24"
            iconName="projects"
            iconColor="transparent"
            iconStroke="#1C1C1C"
            ><icon-projects
          /></icon-base>
          <div class="page-title__text">
            <h1>
              All Projects
              <span
                class="page-title__current-folder"
                v-if="currentFolderName !== ''"
                >/ <font-awesome-icon icon="folder-open" />
                {{ currentFolderName }}</span
              >
            </h1>
          </div>
        </div>

        <div class="input-group input-group--small" :disabled="loading">
          <div class="position-r search-field" :class="{ focus: searchActive }">
            <a-input
              placeholder="Search by name"
              style="width: 240px"
              v-model="searchParametars"
              class="filterPanel__search"
              @focus="searchActive = true"
              @blur="searchActive = false"
              :class="{
                'filterPanel__search--active': searchParametars != '',
              }"
            >
              <a-icon
                slot="addonAfter"
                theme="filled"
                type="close-circle"
                v-show="searchParametars != ''"
                @click="searchParametars = ''"
            /></a-input>
            <icon-base
              class="search-icon"
              :width="20"
              :height="20"
              :viewBox1="64"
              :viewBox2="64"
              iconName="searchThin"
              iconColor="transparent"
              iconStroke="#000"
              ><icon-search-thin
            /></icon-base>
          </div>
        </div>

        <a-dropdown
          :visible="dropdownVisible"
          :trigger="['click']"
          @visibleChange="handleVisibleChange"
          overlayClassName="filters-dropdown"
        >
          <a
            class="filters-dropdown-cta"
            :class="{ 'is-active': dropdownVisible }"
            @click="(dropdownVisible = true), (filtersTypesKey = Math.random())"
          >
            <i v-if="showClearAllFilters"></i>
            <icon-base
              :width="24"
              :height="24"
              :viewBox1="24"
              :viewBox2="24"
              iconName="filters"
              iconColor="transparent"
              iconStroke="#1C1C1C"
              ><icon-filters
            /></icon-base>
            Filters
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <div class="input-group input-group--small">
                  <label>Type</label>
                  <a-select
                    placeholder="All Types"
                    v-if="filterOptions !== null"
                    @change="selectSurveyTypeFilter"
                    :key="filtersTypesKey"
                    class="w-full"
                    :default-value="surveyType || undefined"
                  >
                    <a-select-option
                      value="All Types"
                      :disabled="surveyType === ''"
                    >
                      All Types
                    </a-select-option>
                    <a-select-option
                      v-for="type in filterOptions.SurveyType"
                      :key="type.SurveyTypeId"
                      :value="type.SurveyTypeId"
                    >
                      {{ type.SurveyTypeName }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-menu-item>
              <a-menu-item key="1">
                <div class="input-group input-group--small">
                  <label>Status</label>
                  <a-select
                    placeholder="All Status"
                    v-if="filterOptions !== null"
                    @change="selectSurveyStatusFilter"
                    :key="filtersStatusKey"
                    class="w-full"
                  >
                    <a-select-option
                      value="All Status"
                      :disabled="status === ''"
                    >
                      All Status
                    </a-select-option>
                    <a-select-option
                      v-for="status in filterOptions.SurveyStatus"
                      :key="status.StatusId"
                      :value="status.StatusId"
                    >
                      {{ status.StatusCode }}
                    </a-select-option>
                  </a-select>
                </div></a-menu-item
              >
              <a-menu-item key="2">
                <div class="input-group input-group--small">
                  <label>Author</label>
                  <a-select
                    class="w-full"
                    placeholder="All Authors"
                    v-if="filterOptions !== null"
                    @change="selectSurveyCreatorsFilter"
                    :dropdownMatchSelectWidth="false"
                    :key="filtersCreatorsKey"
                  >
                    <a-select-option
                      value="All Authors"
                      :disabled="launchedBy === ''"
                    >
                      All Authors
                    </a-select-option>
                    <a-select-option
                      v-for="author in allCreators"
                      :key="author.CreateId"
                      :value="author.CreatorName"
                    >
                      {{ author.CreatorName }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-menu-item>
              <a-menu-item key="3">
                <div class="input-group input-group--small text-center">
                  <label>Date</label>
                  <a-range-picker
                    v-if="filterOptions !== null"
                    @change="onChangeDate"
                    format="MM-DD-YYYY"
                    :key="filtersDateKey"
                    :placeholder="dateRangePlaceholder"
                    :class="{ 'is-active': dateRangePlaceholder !== null }"
                  />
                </div>
              </a-menu-item>
              <a-menu-item key="4">
                <div class="flex justify-end gap-15 mt-5 mb-5">
                  <a-button
                    type="link"
                    v-if="showClearAllFilters"
                    @click="resetFilters(), (dropdownVisible = false)"
                    >Reset Filters</a-button
                  >
                  <a-button type="primary" @click="dropdownVisible = false"
                    >Close</a-button
                  >
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>

      <Loader
        v-if="loading"
        text="Loading your projects, please wait!"
        class="flex-1"
      ></Loader>
      <div v-else-if="paginatedProducts.length > 0" class="products-grid">
        <ProductItem
          v-for="product in paginatedProducts"
          :key="product.SurveyID"
          :product="product"
          :user="user"
          @view-report="viewReport($event, undefined)"
          @get-summary="getSummaryData($event)"
          @edit="getSurveyDetails($event, undefined)"
          @define-report="defineReport($event)"
          @open-upload-report-modal="openUploadReportModal($event)"
          @open-upload-raw-data-modal="openUploadRawDataModal($event)"
          @open-upload-tabplan-modal="openTabplanModal($event)"
          @download-data="downloadData($event)"
          @show-survey-services-modal="
            showSurveyServicesModal(
              $event.surveyID,
              $event.surveyName,
              $event.modalType
            )
          "
          @show-survey-clone-modal="
            showSurveyServicesModal(
              $event.surveyID,
              $event.surveyName,
              $event.modalType
            )
          "
          @delete-survey="deleteSurvey($event.surveyID, $event.surveyName)"
          @view-log="viewLog($event.surveyID, $event.surveyName)"
          @add-leg="addLeg($event, undefined)"
          @click="onProductClick($event, undefined)"
          @edit-status="openSurveyStatusModal($event, undefined)"
        ></ProductItem>
      </div>
      <div
        v-else-if="getSurveyListTriggered"
        class="flex items-center justify-center flex-col flex-1 text-center"
      >
        <icon-base
          :width="60"
          :height="60"
          :viewBox1="32"
          :viewBox2="32"
          iconName="no-results"
          iconColor="#415262"
          iconStroke="#fff"
          class="mb-10"
          ><icon-no-results
        /></icon-base>
        <p class="mb-20">
          No projects found. <br />
          Please adjust your criteria or create a new study to get started.
        </p>
        <a-button type="primary" class="text-left" @click="naviToProducts">
          <font-awesome-icon icon="file-alt" class="mr-10" />
          Create New Study
        </a-button>
      </div>
      <div
        class="flex justify-end mt-25"
        v-if="!loading && searchProjectsList.length > pageSize"
      >
        <a-pagination
          :current="currentPage"
          :pageSize="pageSize"
          show-less-items
          :total="pagination.total"
          @change="updateCurrentPage"
        />
      </div>
    </div>

    <a-modal
      :title="
        activeProjectServices === 'SaveProject'
          ? 'Create a folder'
          : 'Rename a folder'
      "
      :visible="modals.projectServicesModal"
      @ok="projectServices()"
      @cancel="modals.projectServicesModal = false"
      :okButtonProps="{ props: { disabled: newProjName === '' } }"
      :okText="activeProjectServices === 'SaveProject' ? 'Create' : 'Save'"
      :width="360"
    >
      <div class="input-group input-group--small">
        <label>
          {{
            activeProjectServices === "SaveProject"
              ? "Folder name"
              : "New folder name"
          }}
        </label>
        <a-input
          :placeholder="
            activeProjectServices === 'SaveProject'
              ? 'e.g. Fall Campaign Tests'
              : ''
          "
          v-model="newProjName"
          ref="projectName"
        />
      </div>
    </a-modal>

    <a-modal
      :title="'Delete a ' + activeSurveyName + ' study'"
      :visible="modals.deleteSurveyModal"
      @ok="surveyServices('DeleteSurvey', 'deleteSurveyModal')"
      @cancel="modals.deleteSurveyModal = false"
      cancelText="No"
      okText="Yes"
      okType="danger"
    >
      <p>Are you sure?</p>
    </a-modal>

    <a-modal
      :title="'Copy ' + activeSurveyName + ' study'"
      :visible="modals.cloneSurveyModal"
      @ok="surveyServices('CloneSurvey', 'cloneSurveyModal')"
      @cancel="modals.cloneSurveyModal = false"
      cancelText="Cancel"
      okText="Ok"
    >
      <p>This will only copy the questionnaire, not the results or data.</p>
      <a-spin tip="Processing..." v-if="modals.loading">
        <div class="spin-content"></div>
      </a-spin>
    </a-modal>

    <a-modal
      title="Study summary"
      :visible="modals.mapMakerTestSummary"
      @cancel="
        (modals.mapMakerTestSummary = false), (estimateCompletionDate = null)
      "
      :footer="null"
      :width="920"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div v-if="surveySummary !== null && surveySummary !== ''">
        <div class="survey-summary__details" v-if="modals.mapMakerTestSummary">
          <a-descriptions
            bordered
            :column="2"
            v-if="surveySummary !== null && surveySummary !== ''"
          >
            <a-descriptions-item label="Name">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.SurveyName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Country">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.Country
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Category">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CategoryName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Brands">
              <ul
                class="mb-0 pl-0"
                v-if="
                  surveySummary !== null &&
                  surveySummary !== '' &&
                  surveySummary.brands &&
                  surveySummary.brands.length > 0
                "
              >
                <li
                  v-for="(item, index) in surveySummary.brands"
                  :key="index"
                  v-show="item.IsSelected"
                >
                  {{ item.brands }}
                </li>
              </ul>
            </a-descriptions-item>

            <a-descriptions-item label="Estimate completion date">
              {{ estimateCompletionDate || "/" }}
            </a-descriptions-item>
            <a-descriptions-item label="Custom Questions" :span="2">
              <template v-if="customQuestionsList !== null">
                <ol
                  class="mb-0 pl-15"
                  v-if="customQuestionsList.CustomQuestions.length > 0"
                >
                  <li
                    v-for="(item, index) in customQuestionsList.CustomQuestions"
                    :key="index"
                  >
                    {{ item.questionText }}
                  </li>
                </ol>
                <span v-else>/</span>
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <div
          class="survey-summary__details survey-summary__details--total mt-25"
        >
          <a-descriptions bordered :column="2">
            <a-descriptions-item label="Total price">
              <span class="flex items-center">
                ${{
                  formatPrice(surveySummaryPrice ? surveySummaryPrice.total : 0)
                }}
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      title="Study summary"
      :visible="modals.packTestSummary"
      @cancel="studySummaryCloseModal"
      :footer="null"
      :width="920"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div
        v-if="
          surveySummary !== null &&
          surveySummary !== '' &&
          modals.packTestSummary
        "
      >
        <a-row :gutter="16" class="flex mb-25">
          <a-col
            :span="12"
            style="width: 52%"
            v-if="
              surveySummary !== null &&
              surveySummary.activeShelf !== undefined &&
              surveySummary.activeShelf.shelfName !== null
            "
          >
            <ActiveShelf
              :showEdit="false"
              :showRetailer="true"
              :activeShelf="surveySummary.activeShelf"
              class="h-full"
            ></ActiveShelf>
          </a-col>
          <a-col
            :span="12"
            style="width: 48%"
            v-if="
              surveySummary !== null &&
              surveySummary.selectedProducts !== undefined &&
              surveySummary.selectedProducts.length
            "
          >
            <SelectedProducts
              :showEdit="false"
              :selectedProducts="surveySummary.selectedProducts"
            ></SelectedProducts
          ></a-col>
        </a-row>
        <div class="survey-summary__details">
          <a-descriptions
            bordered
            :column="2"
            v-if="surveySummary !== null && surveySummary !== ''"
          >
            <a-descriptions-item label="Name">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.SurveyName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Country">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.Country
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Category">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CategoryName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Brand">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.BrandName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Leg(s)">
              <ul class="mb-0 pl-0">
                <li v-for="(item, index) in uniqueFriendlyNames" :key="index">
                  {{ item }}
                  <br v-if="index < uniqueFriendlyNames.length" />
                </li>
              </ul>
            </a-descriptions-item>

            <a-descriptions-item label="Deep Dive Product(s)">
              <ul
                v-if="
                  surveySummary !== null &&
                  surveySummary.deepDiveProducts !== undefined &&
                  surveySummary.deepDiveProducts.length
                "
                class="pl-0 mb-0"
              >
                <li
                  v-for="(item, index) in surveySummary.deepDiveProducts"
                  :key="index"
                >
                  {{ item.productName
                  }}<span
                    v-if="index + 1 < surveySummary.deepDiveProducts.length"
                    >,</span
                  ><span
                    v-if="
                      index + 1 === surveySummary.deepDiveProducts.length &&
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                    >,</span
                  >
                  <br v-if="index < surveySummary.deepDiveProducts.length" />
                </li>
              </ul>
              <span v-else>/</span>
              <ul
                v-if="
                  surveySummary !== null &&
                  surveySummary.uniqueCompetitvedeepDiveProducts !==
                    undefined &&
                  surveySummary.uniqueCompetitvedeepDiveProducts.length
                "
                class="pl-0 mb-0"
              >
                <li
                  v-for="(
                    item, index
                  ) in surveySummary.uniqueCompetitvedeepDiveProducts"
                  :key="index"
                >
                  {{ item
                  }}<span
                    v-if="
                      index + 1 <
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                    >,</span
                  >
                  <br
                    v-if="
                      index <
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                  />
                </li>
              </ul>
            </a-descriptions-item>
            <a-descriptions-item label="Communication objective(s)">
              {{
                surveySummary !== null &&
                surveySummary.SurveyConfig.CommunicationObjectiveName1 !== null
                  ? surveySummary.SurveyConfig.CommunicationObjectiveName1
                  : "/"
              }}<span
                v-if="
                  surveySummary !== null &&
                  surveySummary.SurveyConfig.CommunicationObjectiveName2 !==
                    null
                "
                >,</span
              >
              {{
                surveySummary !== null &&
                surveySummary.SurveyConfig.CommunicationObjectiveName2 !== null
                  ? surveySummary.SurveyConfig.CommunicationObjectiveName2
                  : ""
              }}
            </a-descriptions-item>

            <a-descriptions-item label="Sample per deep dive">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.audience.sampleSize
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item :span="2" label="Emotional attributes">
              <div
                v-if="
                  surveySummary !== null &&
                  surveySummary.AttributeDetails !== undefined &&
                  surveySummary.AttributeDetails.length
                "
                class="pl-0 mb-0"
              >
                <span
                  v-for="(item, index) in surveySummary.AttributeDetails"
                  :key="index"
                >
                  {{ item.AttributeLabel
                  }}<span
                    v-if="index + 1 < surveySummary.AttributeDetails.length"
                    >,</span
                  >
                </span>
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="Cost center">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CostCenter !== ""
                    ? surveySummary.SurveyConfig.CostCenter
                    : "/"
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Estimate completion date">
              {{ estimateCompletionDate }}
            </a-descriptions-item>
            <a-descriptions-item label="Custom Questions" :span="2">
              <template v-if="customQuestionsList !== null">
                <ol
                  class="mb-0 pl-15"
                  v-if="customQuestionsList.CustomQuestions.length > 0"
                >
                  <li
                    v-for="(item, index) in customQuestionsList.CustomQuestions"
                    :key="index"
                  >
                    {{ item.questionText }}
                  </li>
                </ol>
                <span v-else>/</span>
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <div
          class="survey-summary__details survey-summary__details--total mt-25"
        >
          <a-descriptions bordered :column="2">
            <a-descriptions-item label="Total price">
              <span class="flex items-center">
                ${{ formatPrice(surveySummaryPrice.total) }}
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--small"
                >
                  <template slot="title">
                    <span
                      >Total price is a dynamic calculation that ensures a
                      readable sample base for fully assessing your pack designs
                      and communication objectives. This price is impacted by
                      number of legs, number of deep dives and the specific
                      category.</span
                    >
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      :title="'Field summary for ' + activeSurveyName"
      :visible="modals.inFieldSurveySummary"
      @cancel="modals.inFieldSurveySummary = false"
      :footer="null"
      :width="720"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div v-if="inFieldSummaryData.message.rows.length !== 0">
        <table class="infield-summary-table">
          <tbody>
            <tr>
              <td>
                <strong
                  class="mr-5"
                  v-html="
                    inFieldSummaryData.message.rows[
                      inFieldSummaryData.message.rows.length - 1
                    ].name
                  "
                ></strong>
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav ml-5"
                >
                  <template slot="title">
                    <span
                      >{{
                        inFieldSummaryData.message.rows[
                          inFieldSummaryData.message.rows.length - 1
                        ].title
                      }}
                    </span>
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </td>
              <td>
                {{
                  inFieldSummaryData.message.rows[
                    inFieldSummaryData.message.rows.length - 1
                  ].formatted
                }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in inFieldSummaryData.message.rows"
              :key="index"
            >
              <td>
                <strong v-html="item.name" class="mr-5"></strong>
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav"
                >
                  <template slot="title">
                    <span>{{ item.title }} </span>
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </td>
              <td>{{ item.formatted }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      title="Study summary"
      :visible="modals.summary"
      @cancel="modals.summary = false"
      :footer="null"
      :width="920"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>
      <div class="survey-summary__details" v-if="modals.summary">
        <a-descriptions
          bordered
          :column="2"
          v-if="surveySummary !== null && surveySummary !== ''"
        >
          <a-descriptions-item label="Study Name">
            {{
              surveySummary !== null && surveySummary !== ""
                ? surveySummary.SurveyConfig.SurveyName
                : "/"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Category">
            {{
              surveySummary !== null && surveySummary !== ""
                ? surveySummary.SurveyConfig.CategoryName
                : "/"
            }}
          </a-descriptions-item>
          <a-descriptions-item
            label="Audience"
            v-if="
              [97, 98, 99].includes(surveySummary.SurveyConfig.SurveyTypeID)
            "
          >
            Category users 12 months
          </a-descriptions-item>
          <a-descriptions-item
            label="Audience"
            v-else-if="
              surveySummary.SurveyConfig.SurveyTypeName !== 'Pack Test'
            "
          >
            {{
              surveySummary !== null && surveySummary !== ""
                ? surveySummary.audienceDetails
                : "/"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Persona attributes" :span="2">
            <template v-if="surveySummary !== null">
              <div
                v-if="
                  surveySummary.AttributeDetails &&
                  surveySummary.AttributeDetails.length > 0
                "
              >
                <span
                  v-for="(item, index) in surveySummary.AttributeDetails"
                  :key="index"
                >
                  {{ item.AttributeLabel || item.attributeID
                  }}<span
                    v-if="index + 1 < surveySummary.AttributeDetails.length"
                    >,</span
                  >
                </span>
              </div>
              <span v-else>/</span>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="Sample size">
            {{
              surveySummary !== null && surveySummary !== ""
                ? surveySummary.audience.sampleSize *
                  surveySummary.StimDetails.length
                : "/"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Custom Questions" :span="2">
            <template v-if="customQuestionsList !== null">
              <ol
                class="mb-0 pl-15"
                v-if="customQuestionsList.CustomQuestions.length > 0"
              >
                <li
                  v-for="(item, index) in customQuestionsList.CustomQuestions"
                  :key="index"
                >
                  {{ item.questionText }}
                </li>
              </ol>
              <span v-else>/</span>
            </template>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <a-table
        :data-source="surveySummary !== null ? surveySummary.videoItems : []"
        :pagination="{ hideOnSinglePage: true }"
        class="survey-summary__video-info"
        v-if="
          surveySummary !== null &&
          surveySummary !== '' &&
          surveySummary.SurveyConfig.SurveyTypeName !== 'Pack Test' &&
          modals.summary
        "
      >
        <a-table-column
          width="45%"
          key="VideoName"
          title="Video Name"
          data-index="VideoName"
        />
        <a-table-column
          key="thumbnail"
          title="Thumbnail"
          data-index="thumbnail"
          width="18.33%"
        >
          <template slot-scope="text, record">
            <img :src="record.CoverImage" alt="" width="120" />
          </template>
        </a-table-column>
        <a-table-column
          key="AdDuration"
          title="Duration"
          data-index="AdDuration"
          width="18.33%"
        />
        <a-table-column
          key="sampleSize"
          title="Sample size"
          data-index="sampleSize"
          width="18.33%"
        >
          <template>
            <span>
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.audience.sampleSize
                  : ""
              }}</span
            >
          </template>
        </a-table-column>
      </a-table>

      <div class="survey-summary__details survey-summary__details--total">
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="Total price">
            <span class="flex items-center">
              ${{ formatPrice(surveySummaryPrice) }}
              <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
                <template slot="title">
                  <span
                    >Total price is a dynamic calculation that is impacted by
                    the number of videos, their length, any custom questions
                    that have been added and your sample requirements.</span
                  >
                </template>

                <a-icon type="question-circle" />
              </a-tooltip>
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-modal>

    <a-modal
      title="Move study"
      :visible="modals.moveSurveyModal"
      @ok="surveyServices('MoveSurvey', 'moveSurveyModal')"
      @cancel="(modals.moveSurveyModal = false), (moveProjectID = null)"
      cancelText="Cancel"
      okText="Move"
      :okButtonProps="{ props: { disabled: moveProjectID === null } }"
      :width="360"
    >
      <p class="mb-20">Move {{ activeSurveyName }} to a different folder</p>
      <div class="input-group input-group--small">
        <a-select
          class="fullWidth"
          placeholder="Please select a folder"
          @change="selectMoveProject"
          :key="modals.moveSurveyModal"
        >
          <a-select-option
            disabled
            key="currentFolder"
            class="ant-select-dropdown-menu-item--title"
            >Current folder:
            {{ currentProject[0] ? currentProject[0].ProjectName : "" }}
          </a-select-option>
          <a-select-option
            v-for="(item, index) in projectsList"
            :key="index"
            :value="item.ProjectId"
          >
            {{ item.ProjectName }}
          </a-select-option>
        </a-select>
      </div>
      <a-spin tip="Processing..." v-if="modals.loading">
        <div class="spin-content"></div>
      </a-spin>
    </a-modal>

    <a-modal
      title="Update study status"
      :visible="modals.surveyStatus"
      :afterClose="resetCurrentSurveyStatus"
      @cancel="modals.surveyStatus = false"
      :footer="null"
      :width="404"
    >
      <p class="mb-20">
        Please select the new status below and click "Update". [Please note that
        these changes may trigger workflows in 3rd party systems.]
      </p>
      <a-row :gutter="16">
        <a-col :span="24" class="input-group input-group--small">
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            placeholder="Select status"
            :key="Math.random()"
            @change="setSurveyStatus"
            :default-value="currentSurveyStatus.statusTitle || undefined"
            :class="{
              'error-field':
                currentSurveyStatus.statusId === null &&
                surveyStatusValidation.length > 0,
            }"
          >
            <a-select-option
              v-for="item in allSurveyStatus"
              :key="item.CodeId"
              :value="item.CodeId"
            >
              {{ item.CodeName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <a-row :gutter="16" class="mt-30">
        <a-col :span="12">
          <ul
            class="validation-list validation-list--basic"
            v-if="surveyStatusValidation.length > 0"
          >
            <li v-for="(item, index) in surveyStatusValidation" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            class="flex items-center mr-10"
            @click="modals.surveyStatus = false"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveSurveyStatus"
            :disabled="
              currentSurveyStatus.prevTitle ===
                currentSurveyStatus.statusTitle ||
              currentSurveyStatus.statusId === null
            "
          >
            Submit
          </a-button></a-col
        >
      </a-row>
    </a-modal>

    <a-modal
      :title="'Change log for ' + activeSurveyName + ' study'"
      :visible="modals.errorLogsModal"
      @cancel="modals.errorLogsModal = false"
      :footer="null"
      :afterClose="onCloseErrorLogModal"
      class="ant-modal--survey-error-log"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <a-table
        v-else
        tableLayout="auto"
        class="custom-table custom-table--survey-error-log"
        :columns="errorLogColumns"
        :row-key="(record) => record.userName + Math.random()"
        :data-source="[...errorsLogList]"
        :pagination="viewLogPagination"
        @change="viewLogTableChange"
        :expandRowByClick="true"
        :customRow="clickErrorLogRow"
      >
        <template #expandedRowRender="record, index">
          <div class="error-log-details">
            <h3>Request</h3>
            <p v-if="errorsLogList[index].errorDetails === undefined">
              Loading
            </p>
            <div
              v-show="errorsLogList[index].errorDetails !== undefined"
              :key="errorDetailsKey + Math.random()"
              class="mb-15"
            >
              {{
                errorsLogList[index].errorDetails !== undefined
                  ? errorsLogList[index].errorDetails.request
                  : "/"
              }}
            </div>
            <h3>Response</h3>
            <p v-if="errorsLogList[index].errorDetails === undefined">
              Loading
            </p>
            <div
              v-show="errorsLogList[index].errorDetails !== undefined"
              :key="errorDetailsKey + Math.random()"
            >
              {{
                errorsLogList[index].errorDetails !== undefined
                  ? errorsLogList[index].errorDetails.response
                  : "/"
              }}
            </div>

            <div
              v-if="errorsLogList[index].errorDetails !== undefined"
              class="flex error-log-details__copy"
            >
              <div
                @click="copy(index)"
                class="flex items-center cursor-pointer"
              >
                <font-awesome-icon icon="copy" />
                <span>Copy request and response</span>
                <p v-if="errorDetailsCopy" class="error-log-details__copy-done">
                  Copied
                </p>
              </div>
            </div>
          </div>
        </template>
      </a-table>

      <div class="flex justify-end mt-30">
        <a-button type="primary" @click="modals.errorLogsModal = false">
          <span>Done</span>
        </a-button>
      </div>
    </a-modal>

    <a-modal
      title="Upload report"
      :visible="modals.uploadReport"
      @cancel="modals.uploadReport = false"
      :footer="null"
      :width="350"
      :afterClose="closeUploadFileModal"
    >
      <p class="mb-20">
        Upload the report file that will be shared with client. Check the option
        below to send a notification email to the study author, and specify any
        additional recipients.
      </p>
      <div class="position-r mb-20">
        <div
          v-if="pptxFileExist && !pptxUploading"
          class="ant-upload__file-exists"
        >
          <p>The file already exists, do you want to overwrite it?</p>
          <div>
            <a-button type="link" @click="submitReportUpload(true)"
              >Yes</a-button
            >
            /
            <a-button type="link" @click="modals.uploadReport = false"
              >No</a-button
            >
          </div>
        </div>
        <a-upload-dragger
          name="file"
          :multiple="false"
          :showUploadList="false"
          :customRequest="reportUpload"
          class="ant-upload--report-upload"
          :class="{ 'pptx-file-exist': pptxFileExist }"
        >
          <div v-if="!pptxUploading">
            <span v-if="infoReport === undefined"
              >Click or drag file to this area to upload...</span
            >
            <span v-else>
              <font-awesome-icon class="mr-5 green-text" icon="check" />The file
              is added.</span
            >
          </div>
          <div
            v-else
            class="
              image-upload__loader
              flex
              items-center
              justify-center
              flex-col
            "
          >
            <i class="circle-loader"></i>
            <span>Uploading...</span>
          </div>
        </a-upload-dragger>
      </div>
      <div class="general-checkbox mb-15">
        <input
          type="checkbox"
          id="reportSendNotification"
          v-model="reportSendNotification"
          value=""
        /><label for="reportSendNotification" class="normal-lh"
          >Send notification</label
        >
      </div>
      <div
        class="input-group input-group--small mb-30"
        v-if="reportSendNotification"
      >
        <label class="normal-f-weight">Additional recipients</label>
        <a-input
          v-model="reportAdditionalRecipients"
          placeholder="Comma-separated list of email addresses"
        />
      </div>
      <a-col class="flex justify-end mt-40">
        <a-button
          class="flex items-center mr-10"
          @click="modals.uploadReport = false"
        >
          <span>Cancel</span>
        </a-button>
        <a-button
          type="primary"
          @click="submitReportUpload"
          :disabled="infoReport === undefined"
        >
          Submit
        </a-button></a-col
      >
    </a-modal>

    <a-modal
      title="Upload raw data"
      :visible="modals.uploadRawData"
      @cancel="modals.uploadRawData = false"
      :footer="null"
      :width="350"
      :afterClose="closeUploadRawDataModal"
    >
      <p class="mb-20">
        Upload the data file that will be shared with client. Check the option
        below to send a notification email to the study author, and specify any
        additional recipients.
      </p>
      <div class="position-r mb-20">
        <div
          v-if="rawDataExist && !rawDataUploading"
          class="ant-upload__file-exists"
        >
          <p>The file already exists, do you want to overwrite it?</p>
          <div>
            <a-button type="link" @click="submitRawDataUpload(true)"
              >Yes</a-button
            >
            /
            <a-button type="link" @click="modals.uploadRawData = false"
              >No</a-button
            >
          </div>
        </div>
        <a-upload-dragger
          name="file"
          :multiple="false"
          :showUploadList="false"
          :customRequest="reportRawData"
          class="ant-upload--report-upload"
          :class="{ 'pptx-file-exist': rawDataExist }"
        >
          <div v-if="!rawDataUploading">
            <span v-if="infoRawData === undefined"
              >Click or drag file to this area to upload...</span
            >
            <span v-else>
              <font-awesome-icon class="mr-5 green-text" icon="check" />The file
              is added.</span
            >
          </div>
          <div
            v-else
            class="
              image-upload__loader
              flex
              items-center
              justify-center
              flex-col
            "
          >
            <i class="circle-loader"></i>
            <span>Uploading...</span>
          </div>
        </a-upload-dragger>
      </div>
      <div class="general-checkbox mb-15">
        <input
          type="checkbox"
          id="rawDataSendNotification"
          v-model="rawDataSendNotification"
          value=""
        /><label for="rawDataSendNotification" class="normal-lh"
          >Send notification</label
        >
      </div>
      <div
        class="input-group input-group--small mb-30"
        v-if="rawDataSendNotification"
      >
        <label class="normal-f-weight">Additional recipients</label>
        <a-input
          v-model="rawDataAdditionalRecipients"
          placeholder="Comma-separated list of email addresses"
        />
      </div>
      <a-col class="flex justify-end mt-40">
        <a-button
          class="flex items-center mr-10"
          @click="modals.uploadRawData = false"
        >
          <span>Cancel</span>
        </a-button>
        <a-button
          type="primary"
          @click="submitRawDataUpload"
          :disabled="infoRawData === undefined"
        >
          Submit
        </a-button></a-col
      >
    </a-modal>
    <a-modal
      title="Quota List"
      :visible="activeQuotaModule !== null ? true : false"
      :afterClose="resetCurrentSurveyStatus"
      @cancel="activeQuotaModule = null"
      :footer="null"
      :width="804"
      centered
    >
      <QuotaListModal
        v-if="activeQuotaModule !== null"
        :surveyId="activeQuotaModule"
        @close-modal="activeQuotaModule = null"
        @upload-tabplan="openTabplanModal"
      ></QuotaListModal>
    </a-modal>
    <a-modal
      title="Upload Tabplan"
      :visible="modals.uploadTabplan"
      @cancel="modals.uploadTabplan = false"
      :footer="null"
      :width="350"
      :afterClose="closeUploadTabplanModal"
    >
      <p class="mb-20">
        Please upload your tabplan for Inspirient. Your tool data and SPSS will
        be automatically pulled for you and sent to Inspirient for processing.
        You will also receive a copy of the ZIP upload by email.
      </p>
      <div class="position-r mb-20">
        <a-upload-dragger
          name="file"
          :multiple="false"
          :showUploadList="false"
          accept=".xlsx,.xls,.csv"
          :customRequest="tabplanData"
          class="ant-upload--report-upload"
        >
          <div v-if="!tabplanUploading">
            <span v-if="infoTabplanUploadData === null"
              >Click or drag file to this area to upload...</span
            >
            <span v-else>
              <font-awesome-icon class="mr-5 green-text" icon="check" />The file
              is added.</span
            >
          </div>
          <div
            v-else
            class="
              image-upload__loader
              flex
              items-center
              justify-center
              flex-col
            "
          >
            <i class="circle-loader"></i>
            <span>Uploading...</span>
          </div>
        </a-upload-dragger>
      </div>

      <a-col class="flex justify-end mt-40">
        <a-button
          class="flex items-center mr-10"
          @click="modals.uploadTabplan = false"
        >
          <span>Cancel</span>
        </a-button>
        <a-button
          type="primary"
          @click="submitTabplanUpload"
          :disabled="infoTabplanUploadData === null"
        >
          Submit
        </a-button></a-col
      >
    </a-modal>
    <PageLoader v-if="pageLoader" id="pageLoader"></PageLoader>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import PageLoader from "@/components/general/PageLoader.vue";
import DataInfo from "@/components/general/DataInfo.vue";
import ActiveShelf from "@/components/packTestSurvey/ActiveShelf.vue";
import SelectedProducts from "@/components/packTestSurvey/SelectedProducts.vue";
import _ from "lodash";
import { store, mutations } from "@/utils/store.js";
import priceFormating from "@/mixins/priceFormating.js";
import productItem from "@/mixins/productItem.js";
import ProductItem from "@/components/general/ProductItem.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconProjects from "@/components/icons/IconProjects.vue";
import IconFilters from "@/components/icons/IconFilters.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import Loader from "@/components/general/Loader.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";
import QuotaListModal from "@/components/general/QuotaListModal.vue";

export default {
  name: "Home",
  components: {
    PageLoader,
    DataInfo,
    ActiveShelf,
    SelectedProducts,
    ProductItem,
    IconBase,
    IconProjects,
    IconFilters,
    Loader,
    IconSearchThin,
    IconNoResults,
    QuotaListModal,
  },
  mixins: [priceFormating, productItem],
  data() {
    return {
      dropdownVisible: false,
      filtersStatusKey: Math.random(),
      filtersTypesKey: Math.random(),
      filtersCreatorsKey: Math.random(),
      filtersDateKey: Math.random(),
      account: {},
      clients: [],
      surveys: [],
      client: {},
      clientObj: {},
      curPage: 0,
      projectPerPage: -1,
      startDate: "",
      endDate: "",
      launchedBy: "",
      status: "",
      orderby: "",
      surveyType: "",
      typeOptions: [],
      statusOptions: [],
      creatorOptions: [],
      editProjectNameVisible: false,
      newProjName: "",
      pagination: {},
      loading: false,
      showSelectProjectTip: false,
      activeSurveyID: null,
      folderHasProjects: false,
      filterOptions: null,
      pageLoader: false,
      langObj: null,
      searchParametars: "",
      user: null,
      file: "",
      pptxUploading: false,
      pptxFileExist: false,
      uploadReportSurveyID: null,
      reportSendNotification: false,
      reportAdditionalRecipients: "",
      infoReport: undefined,
      dateRangePlaceholder: null,
      rawDataReport: undefined,
      infoRawData: undefined,
      rawDataExist: false,
      rawDataUploading: false,
      rawDataSendNotification: false,
      rawDataAdditionalRecipients: "",
      uploadRawDataSurveyID: null,
      uploadReportSurveyTypeID: null,
      currentPage: 1,
      pageSize: 12,
      searchActive: false,
      getSurveyListTriggered: false,
    };
  },
  watch: {
    selectProject(newVal) {
      if (
        this.modals.projectServicesModal === false &&
        this.modals.deleteProjectModal === false
      ) {
        jsUtils.setWorkingProject(newVal);
        this.getSurveyList();
        this.showSelectProjectTip = this.selectProject !== 0 ? false : true;
      }
      let projectId;

      if (this.selectProject === 0) {
        projectId = 8;
      } else {
        projectId = this.selectProject;
      }
      sessionStorage.setItem("pID", projectId);
    },
    searchProjectsList(newVal) {
      this.pagination.total = newVal.length;
    },
  },
  computed: {
    searchProjectsList() {
      return this.surveys.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.SurveyName.toLowerCase().includes(v));
      });
    },
    allCreators() {
      return _.uniqBy(this.filterOptions.Creators, "CreatorName");
    },
    showClearAllFilters() {
      return (
        this.surveyType !== "" ||
        this.status !== "" ||
        this.launchedBy !== "" ||
        this.startDate !== "" ||
        this.endDate !== ""
      );
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.searchProjectsList.slice(start, end);
    },
    currentFolderName() {
      const project = this.projects.find(
        (project) => project.ProjectId === this.selectProject
      );
      return project ? project.ProjectName : "";
    },
  },
  methods: {
    handleVisibleChange(visible) {
      this.dropdownVisible = visible;
    },
    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    logout() {
      this.$router.push({ name: "login" });
    },
    projectServices(pinned) {
      let data;

      if (this.activeProjectServices === "SaveProject") {
        data = {
          ProjectName: this.newProjName,
          clientID: this.client.ClientId,
          userID: this.user.EncrypteduserID,
        };
      } else if (this.activeProjectServices === "UpdateProjects") {
        data = {
          ProjectName: this.newProjName,
          ProjetId: this.activeProjectServicesID,
        };
      } else if (this.activeProjectServices === "PinProjects") {
        data = {
          Pinned: !pinned,
          ProjetId: this.activeProjectServicesID,
        };
      } else {
        data = {
          ProjetId: this.activeProjectServicesID,
        };
      }

      const projectData = {
        endpoint: this.activeProjectServices,
        data,
      };

      const projectServices = new Promise((resolve) => {
        wsUtils.ProjectServices(projectData, resolve);
      });

      projectServices.then(() => {
        sessionStorage.setItem("pID", this.selectProject);
        this.getProjectList();
        this.modals.projectServicesModal = false;
        this.modals.deleteProjectModal = false;
      });
    },
    deleteProject(projectId) {
      let hasSurveys = this.surveys.some(function (survey) {
        return survey.ProjectID === projectId;
      });
      let _this = this;

      this.folderHasProjects = hasSurveys;
      this.modals.deleteProjectModal = true;

      if (hasSurveys) {
        this.$error({
          title: "Delete folder",
          onOk() {
            _this.modals.deleteProjectModal = false;
          },
          content:
            "The folder you're trying to delete is not empty. Before deleting a folder, please ensure the folder is empty by moving or deleting projects within.",
        });
      } else {
        this.$confirm({
          title: "Delete folder",
          content: () => (
            <div>Are you sure you want to delete this folder?</div>
          ),
          onOk() {
            _this.projectServices();
          },
          okText: "Yes",
          onCancel() {
            _this.modals.deleteProjectModal = false;
          },
        });

        this.activeProjectServices = "DeleteProject";
        this.activeProjectServicesID = projectId;
      }
    },
    setSelectedProject(id) {
      if (
        this.modals.projectServicesModal === false &&
        this.modals.deleteProjectModal === false &&
        this.activeProjectServices !== "PinProjects"
      ) {
        this.selectProject = id;
        sessionStorage.setItem("pID", id);
      }
    },

    onChangeDate(date, dateString) {
      this.startDate = dateString[0];
      this.endDate = dateString[1];
      this.getSurveyList();
    },
    getFilterOption() {
      const filterPromise = new Promise((resolve) => {
        wsUtils.GetMyTestFilter(this.client.ClientId, resolve);
      });

      filterPromise.then((data) => {
        this.filterOptions = data;
      });
    },
    getDateRange(timePeriod) {
      const now = new Date();
      let startDate, endDate;
      switch (timePeriod) {
        case "week":
          startDate = new Date(now.setDate(now.getDate() - now.getDay()));
          endDate = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
          break;
        case "month":
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          break;
        case "year":
          startDate = new Date(now.getFullYear(), 0, 1);
          endDate = new Date(now.getFullYear(), 11, 31);
          break;
        default:
          startDate = null;
          endDate = null;
          break;
      }
      return {
        startDate: startDate
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
        endDate: endDate
          .toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
      };
    },
    naviToProducts() {
      if (this.selectProject !== 0) {
        // we alraedy selected a folder/project
        sessionStorage.removeItem("editableSurvey");
        this.$router.push({ name: "products" });
        jsUtils.deleteCookie("survey-type");
      } else {
        // show a message
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "To create a study, please select a folder from the list below",
          placement: "bottomLeft",
        });
      }
    },
    selectSurveyTypeFilter(id) {
      if (id !== "All Types") {
        this.surveyType = id;
      } else {
        this.surveyType = "";
      }
      this.getSurveyList();
    },
    selectSurveyStatusFilter(id) {
      if (id !== "All Status") {
        this.status = id;
      } else {
        this.status = "";
      }
      this.getSurveyList();
    },
    selectSurveyCreatorsFilter(id) {
      if (id !== "All Authors") {
        this.launchedBy = id;
      } else {
        this.launchedBy = "";
      }
      this.getSurveyList();
    },
    reportUpload(info) {
      this.infoReport = info;
    },
    reportRawData(info) {
      this.infoRawData = info;
    },
    submitRawDataUpload(data) {
      this.rawDataUploading = true;
      let form = new FormData();
      let overwrite = data === true ? true : false;
      form.append("file", this.infoRawData.file);

      const UploadRawFileAWS = new Promise((resolve) => {
        wsUtils.UploadRawFileAWS(
          {
            form,
            overwrite,
            userId: this.user.EncrypteduserID,
            surveyId: this.uploadRawDataSurveyID,
            notifications: this.rawDataSendNotification,
            additionalRecipients: this.rawDataAdditionalRecipients.replace(
              /\s/,
              ""
            ),
          },
          resolve
        );
      });

      UploadRawFileAWS.then((data) => {
        this.rawDataUploading = false;
        if (data.success && data.message === "File already exist") {
          this.rawDataExist = true;
        } else {
          this.closeUploadRawDataModal();
          this.$notification["success"]({
            message: "Upload report",
            description: "File upload completed successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      });
    },
    submitReportUpload(data) {
      this.pptxUploading = true;
      let form = new FormData();
      let overwrite = data === true ? true : false;
      form.append("file", this.infoReport.file);
      let wsName = "UploadpptFileAWS";

      if (this.uploadReportSurveyTypeID === 97) {
        wsName = "UploadCustomReport";
      }

      const uploadpptFileAWS = new Promise((resolve) => {
        wsUtils[wsName](
          {
            form,
            overwrite,
            userId: this.user.EncrypteduserID,
            surveyId: this.uploadReportSurveyID,
            notifications: this.reportSendNotification,
            additionalRecipients: this.reportAdditionalRecipients.replace(
              /\s/,
              ""
            ),
          },
          resolve
        );
      });

      uploadpptFileAWS.then((data) => {
        this.pptxUploading = false;
        this.uploadReportSurveyTypeID = null;
        if (data.success && data.message === "File already exist") {
          this.pptxFileExist = true;
        } else {
          this.closeUploadFileModal();
          this.getSurveyList();
          this.$notification["success"]({
            message: "Upload report",
            description: "File upload completed successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      });
    },
    closeUploadFileModal() {
      this.pptxFileExist = false;
      this.reportSendNotification = false;
      this.reportAdditionalRecipients = "";
      this.infoReport = undefined;
      this.uploadReportSurveyID = null;
      this.modals.uploadReport = false;
    },
    closeUploadRawDataModal() {
      this.rawDataExist = false;
      this.rawDataSendNotification = false;
      this.rawDataAdditionalRecipients = "";
      this.infoRawData = undefined;
      this.uploadRawDataSurveyID = null;
      this.modals.uploadRawData = false;
    },
    resetFilters() {
      this.surveyType = "";
      this.status = "";
      this.launchedBy = "";
      this.startDate = "";
      this.endDate = "";
      this.searchParametars = "";
      this.dateRangePlaceholder = null;
      mutations.setDefaultTimePeriod(null);
      mutations.setDefaultSurveyType(null);
      this.getSurveyList();
      this.filtersStatusKey = Math.random();
      this.filtersTypesKey = Math.random();
      this.filtersCreatorsKey = Math.random();
      this.filtersDateKey = Math.random();
    },
    setTimePeriodFilter() {
      const allTimePeriod = "ALL";
      if (
        store.defaultTimePeriod !== null &&
        store.defaultTimePeriod !== allTimePeriod
      ) {
        const dateRange = this.getDateRange(
          store.defaultTimePeriod.toLowerCase()
        );
        this.dateRangePlaceholder = [dateRange.startDate, dateRange.endDate];
        this.onChangeDate(null, this.dateRangePlaceholder);
      } else if (store.defaultTimePeriod === allTimePeriod) {
        this.resetFilters();
      }
    },
  },
  activated() {
    this.pageLoader = false;
    this.prevProjectID = jsUtils.getWorkingProject();
    this.getFilterOption();
    this.getProjectList();
    let getSurveys = jsUtils.getSurveysApiTrigger();
    if (getSurveys) {
      this.getSurveyList();
    }
    jsUtils.clearSurveyPrice();
    jsUtils.clearPackTestPrice();
    this.setTimePeriodFilter();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.user = jsUtils.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.home {
  overflow: auto;
  background: var(--body);
  height: 100%;
  &__left-side {
    padding: 40px 0 20px;
    text-align: left;
    font-weight: 500;
    width: 240px;
    position: fixed;
    top: 0;
    left: 96px;
    z-index: 998;
    overflow: auto;
    height: 100%;
  }

  &__left-side-nav {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  &__left-side-cta {
    padding: 0 20px;
    margin-bottom: 26px;
    .ant-btn {
      svg {
        margin-right: 8px;
      }
    }
  }

  &__left-side-nav-item {
    font-size: 13px;
    padding: 12px 10px;
    cursor: pointer;
    transition: 0.3s ease all;
    position: relative;
    &:before {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      top: 0;
      content: "";
      background: linear-gradient(90deg, #fbfbfb 10%, #e9edf5 100%);
      left: 0;
      transform: translateX(-100%);
      transition: 0.35s ease all;
    }
    &:hover {
      color: var(--hsblueDark);
      &:before {
        transform: translateX(0);
      }
      .home__left-side-nav-tools {
        opacity: 1;
        visibility: visible;
      }
    }
    &.is-selected {
      background: linear-gradient(90deg, #fbfbfb 10%, #e9edf5 100%);
      color: var(--hsblueDark);

      > svg {
        color: var(--hsblueDark);
      }
    }
    > svg {
      margin-right: 10px;
      margin-left: 9px;
      font-size: 12px;
    }
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 110px;
      white-space: nowrap;
    }
    &--add {
      color: var(--red);
      &:hover {
        color: var(--red);
      }
    }
  }

  &__left-side-nav-tools {
    padding-right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
    gap: 8px;
    button {
      cursor: pointer;
      svg {
        font-size: 13px;
        color: #000;
        transition: 0.3s ease all;
        &.pinned {
          color: var(--hsblueDark);
        }
      }
      &:hover {
        svg {
          color: var(--hsblueDark);
        }
      }
    }
  }

  &__right-side {
    background: #fff;
    border-radius: 50px 0 0 50px;
    padding: 40px;
    margin-left: 240px;
    height: max-content;
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__right-side-filters {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    .page-title {
      margin: 0 auto 0 0;
      h1 {
        color: var(--darkText);
        font-size: 30px;
      }
    }
  }
  &__right-side-filters-clear {
    font-size: 13px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.ant-modal {
  .ant-spin-nested-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.85);
  }
}

.ant-upload {
  &--report-upload {
    ::v-deep .ant-upload {
      height: 90px;
    }
    &.pptx-file-exist {
      ::v-deep .ant-upload.ant-upload-drag {
        border-color: red;
      }
    }
  }
  &__file-exists {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: 0 10px;
    text-align: center;
    p {
      color: red;
      margin: 0;
    }
    .ant-btn {
      font-weight: 600;
      padding: 0 5px;
      color: var(--darkGrey);
    }
  }
}
</style>

<style lang="scss">
.ant-select-dropdown-menu-item {
  &--title {
    font-size: 12px !important;
    padding: 2px 12px !important;
    color: var(--hsblue) !important;
    cursor: default !important;
  }
}

.ant-tooltip {
  &--small {
    font-size: 11px !important;
    .ant-tooltip-inner {
      padding: 4px 8px 1px;
      min-height: auto;
      background-color: #2c3e50;
    }
  }
  &--step-nav {
    .ant-tooltip-inner {
      font-size: 12px;
      min-height: auto;
      color: #000;
      background: #fff;
      border: 1px solid #ffffff;
      box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
      padding: 8px 8px 6px;
    }
    .ant-tooltip-arrow {
      &:before {
        background: #fff;
      }
    }
    .ant-tooltip-content {
      border: 1px solid #e7e7e7;
      border-radius: 4px;
    }
  }
}

.ant-modal {
  &--survey-error-log {
    .ant-modal-wrap {
      padding: 20px;
    }
    .ant-modal {
      width: 100% !important;
      max-width: 1100px;
    }
  }
}
</style>
